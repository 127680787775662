import Radium from 'radium';

function Footer() {
    const date = (new Date).getFullYear();

    return (
        <div>
            <hr className="line-vr-section"/>

            <div className="topbanner-footer">
                <div className="section-padding banner-img valign bg-img bg-fixed" data-overlay-darkgray="1"
                     data-background="/vendor/img/itinnove/bureau_V2.jpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-30 text-left caption">
                                <div className="section-title">Contactez nous</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <h6>Téléphone</h6>
                                <h5 className="mb-30"><a href="tel:0310940440">03 10 94 04 40</a></h5>
                            </div>
                            <div className="col-md-4">
                                <h6>Email</h6>
                                <h5 className="mb-30"><a href="mailto:contact@itinnove.com">contact@itinnove.com</a>
                                </h5>
                            </div>
                            <div className="col-md-4">
                                <h6>Adresse</h6>
                                <h5 className="mb-30"><a href="https://maps.app.goo.gl/ewhgzNiReKrHZkMD7">7 rue Roger
                                    Bouvron<br/>10600 Barberey-Saint-Sulpice</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sub-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="text-left">
                                © {date}, IT INNOVE. Tous droits réservés.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text-right-left">
                                <p><a href="cgu.pdf">Conditions générales d'utilisations</a> <br/> <a
                                    href="privacy.pdf"> Politique de confidentialité</a> <br/> <a
                                    href="mentions-legales.pdf">Mentions légales</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Radium(Footer);
