import routes, {getRoutes} from "./routes";
import {Route, Routes, Navigate} from "react-router-dom";
import Radium, { StyleRoot } from 'radium';

function App() {

  return (
        <StyleRoot>
          <div>
            <Routes>
              {getRoutes(routes())}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </StyleRoot>
  );
}

export default Radium(App);
