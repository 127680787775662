import Radium from 'radium';
import Footer from "../components/Footer";

function About() {
    const end = (new Date).getFullYear() - 2017;
    return (
        <div>
            {/*Header Banner */}
            <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="4" data-background="/img/about/photo_equipe_2.png" style={{backgroundPosition: "center"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center caption mt-90">
                            <h1>À Propos de nous</h1>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="line-vr-section"/>
            {/*About*/}
            <div className="about section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-title">{end} Ans d'Expérience</div>
                            <p>La société IT INNOVE a été fondée en 2017 par M. Blaise BARRÉ.Elle a été bâtie sur un modèle conviviale et familiale.</p>
                            <p>Design inilla duiman at elit finibus viverra nec a lacus themo the drudea senele misuscipit non sagie the fermen. Viverra tristique jusio the ivite dianne onen nivami acsestion architecture augue artine.</p>
                            <p>Architecture innovation tristique usto duision vitae diam neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="about-img">
                                {/*<div className="img"> <img src="img/about.png" className="img-fluid" alt=""/> </div>*/}
                                <video controls width="100%">
                                    <source src="/media/cc0-videos/flower.webm" type="video/webm"/>

                                    <source src="/media/cc0-videos/flower.mp4" type="video/mp4"/>

                                    Download the
                                    <a href="/media/cc0-videos/flower.webm">WEBM</a>
                                    or
                                    <a href="/media/cc0-videos/flower.mp4">MP4</a>
                                    video.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Testimonial*/}
            <div className="team section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-15">
                            <div className="section-title">Team</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="item toright mb-30">
                                <div className="img-team-about"><img src="/img/about/portraits/Blaise.png" alt="" className="img-circle-about"/></div>
                                <div className="info valign">
                                    <div className="full-width">
                                        <h6>Blaise Barré</h6> / <span>PDG Dev. Back</span>
                                        <p>PDG de la société IT INNOVE</p>
                                        <div className="social">
                                            <div className="full-width">
                                                <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>
                                                <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>
                                                <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="item mb-30">
                                <div className="img-team-about"><img src="/img/about/portraits/cleo.png" alt="" className="img-circle-about"/></div>
                                {/*<div className="img"><img src="/vendor/img/itinnove/about/team/cleo.JPG" alt=""/></div>*/}
                                <div className="info valign">
                                    <div className="full-width">
                                        <h6>Cléo MATTEI</h6> /<br/> <span>Développeur Full Stack</span>
                                        <p>Développeur Full Stack Web et mobile</p>
                                        <div className="social">
                                            <div className="full-width">
                                                <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>
                                                <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>
                                                <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="item toright mb-30">
                                <div className="img-team-about"><img src="/img/about/portraits/Antonin.png" alt="" className="img-circle-about"/></div>
                                <div className="info valign">
                                    <div className="full-width">
                                        <h6>Antonin</h6> / <span>dipl. Arch ETH/SIA</span>
                                        <p>Développeur Full Stack Web et mobile</p>
                                        <div className="social">
                                            <div className="full-width">
                                                <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>
                                                <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>
                                                <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="item mb-30">
                                <div className="img-team-about"><img src="/img/about/portraits/Melvin.png" alt="" className="img-circle-about"/></div>
                                <div className="info valign">
                                    <div className="full-width">
                                        <h6>Melvin</h6> / <br/><span>dipl. Arch ETH/SIA</span>
                                        <p>Développeur Full Stack Web et mobile</p>
                                        <div className="social">
                                            <div className="full-width">
                                                <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>
                                                <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>
                                                <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Clients*/}

            <div className="blog section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">Nos Clients</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/logo-billatraining.jpeg"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/Hidora-Logo.png" alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/logo-apiterra.png"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/logo-intercaves.png"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/logo-magiline.png"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client"
                                 src="/vendor/img/itinnove/clients/logo-massive-agency.png" alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/logo-sy.png"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/Taittinger-logo.png"
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
);
}

export default Radium(About);
