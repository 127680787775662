import Radium from 'radium';

function Devis() {
    return (
        <div>

            {/*Header Banner */}
            <div className="section-padding valign bg-img bg-img-center bg-fixed" data-overlay-dark="6" data-background="/vendor/img/itinnove/process/dev-mobile.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center caption">
                            <h1>Devis gratuit et rapide</h1>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="line-vr-section"/>

                {/* Devis */}
                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                           {/* image */}
                            <div className="col-md-12">
                                <div className="row align-items-stretch savoye-photos">
                                    <div className="col-md-12 items mb-30" >

                                        <iframe src="https://tally.so/r/w7NR4L?"
                                                width="1000px" height="1000px" frameBorder="0" marginHeight="0"
                                                marginWidth="0"
                                                title="Devis développement / 100 % gratuit et rapide !"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>
    );
}

export default Radium(Devis);
