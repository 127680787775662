import Radium from 'radium';

function Meeting() {
    return (
        <div>
            {/*Header Banner */}
            <div className="section-padding valign bg-img bg-img-center bg-fixed" data-overlay-dark="6" data-background="/vendor/img/itinnove/process/dev-mobile.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center caption">
                            <h1>Rendez-vous</h1>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="line-vr-section"/>
                {/* Meeting */}
                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                            {/* image */}
                            <div className="col-md-12">
                                <div className="row align-items-stretch">
                                    <div className="col-md-12">
                                        <iframe
                                            src="https://calendly.com/blaisebarre/30min?embed_domain=www.itinnove.com&amp;embed_type=Inline&amp;hide_gdpr_banner=1"
                                            width="1000px" height="1000px" frameBorder="0"
                                            ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </div>
    );
}

export default Radium(Meeting);
