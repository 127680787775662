import Radium from 'radium';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Index() {
    return (
        <div>
            <header className="header slider-fade">
                <div className="owl-carousel owl-theme">
                    <div className="text-left item bg-img" data-overlay-dark="6"
                         data-background="/vendor/img/itinnove/bureau_V3.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 mt-30">
                                        <div className="o-hidden">
                                            <h5 className="logo-itinnove">IT INNOVE</h5>
                                            <h1>Applications mobiles</h1>
                                            <p>Nous concevons l'application mobile sur mesure adaptée à vos besoins.</p>
                                            <div>
                                                <a href="/devis" className="btn float-btn flat-btn mr-5">Lancez votre projet</a>
                                                <a href="/meeting" className="btn float-btn flat-btn">Prendre un RDV</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left item bg-img" data-overlay-dark="6"
                         data-background="/vendor/img/itinnove/bg-template3.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 mt-30">
                                        <div className="o-hidden">
                                            <h5 className="logo-itinnove">IT INNOVE</h5>
                                            <h1>Applications web</h1>
                                            <p>Nous concevons l'application web sur mesure adaptée à vos besoins</p>
                                            <div>
                                                <a href="/devis" className="btn float-btn flat-btn mr-5">Devis
                                                    gratuit</a>
                                                <a href="/meeting" className="btn float-btn flat-btn">Prendre un
                                                    RDV</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left item bg-img" data-overlay-dark="6"
                         data-background="/vendor/img/itinnove/mockup-maquettage.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 mt-30">
                                        <div className="o-hidden">
                                            <h5 className="logo-itinnove">IT INNOVE</h5>
                                            <h1>Maquettes graphiques</h1>
                                            <p>Nous créons les maquettes graphiques de votre future application. .</p>
                                            <div>
                                                <a href="/devis" className="btn float-btn flat-btn mr-5">Devis
                                                    gratuit</a>
                                                <a href="/meeting" className="btn float-btn flat-btn">Prendre un
                                                    RDV</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/*Projects */}
            {/*<div className="savoye-project section-padding">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-12 text-center animate-box" data-animate-effect="fadeInUp">*/}
            {/*                <div className="section-title">Notre process</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-12">*/}
            {/*                <div className="projects3 animate-box" data-animate-effect="fadeInUp">*/}
            {/*                    <figure>*/}
            {/*                        <img src="/vendor/img/itinnove/process/reunion.jpg" alt="" className="img-fluid"/>*/}
            {/*                    </figure>*/}
            {/*                    <div className="caption">*/}
            {/*                        <h6>IT INNOVE</h6>*/}
            {/*                        <h4>Prise de contact avec le client</h4>*/}
            {/*                        <p>Réunions en visio, téléphone ou physique pour connaître les besoins du client.*/}
            {/*                            Nous nous mettons d'accord sur les étapes nécessaires à la réalisation du projet*/}
            {/*                            et nous nous adaptons aux exigences clients.</p>*/}
            {/*                        <a href="/devis" className="btn float-btn flat-btn mr-5">Devis gratuit</a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="projects3 left animate-box" data-animate-effect="fadeInUp">*/}
            {/*                    <figure>*/}
            {/*                        <img src="/vendor/img/itinnove/process/maquettage.jpg" alt="" className="img-fluid"/>*/}
            {/*                    </figure>*/}
            {/*                    <div className="caption">*/}
            {/*                        <h6>IT INNOVE</h6>*/}
            {/*                        <h4>Maquettage</h4>*/}
            {/*                        <p>Notre graphiste va réaliser la maquette du projet jusqu'à la validation finale*/}
            {/*                            par le client.</p>*/}
            {/*                        <a href="/src/pages/About" className="btn float-btn flat-btn">Maquettage</a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="projects3 animate-box" data-animate-effect="fadeInUp">*/}
            {/*                    <figure>*/}
            {/*                        <img src="/vendor/img/itinnove/process/dev-mobile.jpg" alt="" className="img-fluid sepia"/>*/}
            {/*                    </figure>*/}
            {/*                    <div className="caption">*/}
            {/*                        <h6>IT INNOVE</h6>*/}
            {/*                        <h4>Développement</h4>*/}
            {/*                        <p>Nos équipes se répartissent le front et le back de l'application web et mobile en*/}
            {/*                            passant par des phases de tests jusqu'à sa réalisation finale et à la validation*/}
            {/*                            du client.</p>*/}
            {/*                        <a href="/development" className="btn float-btn flat-btn">Développement</a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="projects3 left animate-box" data-animate-effect="fadeInUp">*/}
            {/*                    <figure>*/}
            {/*                        <img src="/vendor/img/itinnove/process/prod.jpeg" alt="" className="img-fluid"/>*/}
            {/*                    </figure>*/}
            {/*                    <div className="caption">*/}
            {/*                        <h6>IT INNOVE</h6>*/}
            {/*                        <h4>Mise en production</h4>*/}
            {/*                        <p>L'application est mise en production, sur Internet et sur Google Play et l'App*/}
            {/*                            Store.</p>*/}
            {/*                        <a href="/production" className="btn float-btn flat-btn">Mise en production</a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*/!*Clients *!/*/}

            {/*<div className="blog section-padding">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-12">*/}
            {/*                <div className="section-title">Nos Clients</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="frame-img-client">*/}
            {/*                <img className="img-client" src="/vendor/img/itinnove/clients/logo-billatraining.jpeg"*/}
            {/*                     alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="frame-img-client">*/}
            {/*                <img className="img-client" src="/vendor/img/itinnove/clients/Hidora-Logo.png" alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="frame-img-client">*/}
            {/*                <img className="img-client" src="/vendor/img/itinnove/clients/logo-apiterra.png"*/}
            {/*                     alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="frame-img-client">*/}
            {/*                <img className="img-client" src="/vendor/img/itinnove/clients/logo-intercaves.png"*/}
            {/*                     alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="frame-img-client">*/}
            {/*                <img className="img-client" src="/vendor/img/itinnove/clients/logo-magiline.png"*/}
            {/*                     alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="frame-img-client">*/}
            {/*                <img className="img-client"*/}
            {/*                     src="/vendor/img/itinnove/clients/logo-massive-agency.png" alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="frame-img-client">*/}
            {/*                <img className="img-client" src="/vendor/img/itinnove/clients/logo-sy.png"*/}
            {/*                     alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="frame-img-client">*/}
            {/*                <img className="img-client" src="/vendor/img/itinnove/clients/Taittinger-logo.png"*/}
            {/*                     alt=""/>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}


        </div>
    );
}

export default Radium(Index);
