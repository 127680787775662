import Radium from 'radium';
import Footer from "../components/Footer";

function Services() {
    return (
        <div>
            {/*Header Banner*/}
            <div className="banner-header section-padding valign bg-img bg-img-center bg-fixed" data-overlay-darkgray="6" data-background="/vendor/img/itinnove/bg-espace-travail.jpeg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center caption mt-90">
                            <h1>Nos Services</h1>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="line-vr-section"/>

            {/*Services */}
            <div className="services-feat section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="square-flip">
                                <div className="square bg-img" data-background="/vendor/img/itinnove/services/conception.jpg">
                                    <div className="square-container d-flex align-items-end justify-content-end">
                                        <div className="box-title text-vertical">
                                            <h4>Conception - Prototypage</h4>
                                        </div>
                                    </div>
                                    <div className="flip-overlay"></div>
                                </div>
                                <div className="square2">
                                    <div className="square-container2">
                                        <h4>Conception - Prototypage</h4>
                                        <p>Pour vérifier la faisabilité d'une application, nous pouvons réaliser un POC,
                                            ou "Proof of Concetp", autrement dit une application web et mobile disposant
                                            uniquement d'une fonctionnalité précise pour en vérifier la faisabilité ou l'utilisation et
                                            ainsi aller plus loin dans le développement. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="square-flip">
                                <div className="square bg-img" data-background="/vendor/img/itinnove/services/mockup-web-mobile.png">
                                    <div className="square-container d-flex align-items-end justify-content-end">
                                        <div className="box-title text-vertical">
                                            <h4>Maquettage</h4>
                                        </div>
                                    </div>
                                    <div className="flip-overlay"></div>
                                </div>
                                <div className="square2">
                                    <div className="square-container2">
                                        <h4>Maquettage</h4>
                                        <p>La maquette graphique est la première étape. Elle permet de structurer une application web et mobile,
                                            d'en valider la charte graphique (les couleurs), l'apparence générale et la disposition de chaque bloc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="square-flip">
                                <div className="square bg-img" data-background="/vendor/img/itinnove/bg-template.jpeg">
                                    <div className="square-container d-flex align-items-end justify-content-end">
                                        <div className="box-title text-vertical">
                                            <h4>Site web</h4>
                                        </div>
                                    </div>
                                    <div className="flip-overlay"></div>
                                </div>
                                <div className="square2">
                                    <div className="square-container2">
                                        <h4>Site web</h4>
                                        <p>Un site web vous permet de présenter votre activité, votre produit et vos services mais également de rester en contact avec vos clients
                                            et de disposer de fonctionnalités utiles pour vous faciliter le travail. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="square-flip">
                                <div className="square bg-img" data-background="/vendor/img/itinnove/services/responsive.png">
                                    <div className="square-container d-flex align-items-end justify-content-end">
                                        <div className="box-title text-vertical">
                                            <h4>Front-End</h4>
                                        </div>
                                    </div>
                                    <div className="flip-overlay"></div>
                                </div>
                                <div className="square2">
                                    <div className="square-container2">
                                        <h4>Front-End</h4>
                                        <p>L'intégration des maquettes permet de donner vie à votre application web et mobile.
                                            Les données restent factices mais vous pouvez naviguer de page en page et avoir un aperçu concret de chauqe fonctionnalité. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="square-flip">
                                <div className="square bg-img" data-background="/vendor/img/itinnove/process/dev-mobile.jpg">
                                    <div className="square-container d-flex align-items-end justify-content-end">
                                        <div className="box-title text-vertical">
                                            <h4>Back-End</h4>
                                        </div>
                                    </div>
                                    <div className="flip-overlay"></div>
                                </div>
                                <div className="square2">
                                    <div className="square-container2">
                                        <h4>Back-End</h4>
                                        <p>L'intégration des API dans le front-end de votre application web et mobile permet enfin de donner vie à toutes les pages. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="square-flip">
                                <div className="square bg-img" data-background="/vendor/img/itinnove/services/services-maquettage.jpg">
                                    <div className="square-container d-flex align-items-end justify-content-end">
                                        <div className="box-title text-vertical">
                                            <h4>Applications mobiles</h4>
                                        </div>
                                    </div>
                                    <div className="flip-overlay"></div>
                                </div>
                                <div className="square2">
                                    <div className="square-container2">
                                        <h4>Applications mobile</h4>
                                        <p>Une application mobile vous permet de présenter votre activité, votre produit et vos services mais également de rester en contact avec vos clients
                                            et de disposer de fonctionnalités utiles pour vous faciliter le travail. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Radium(Services);
