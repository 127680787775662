import Radium from 'radium';
import Footer from "../components/Footer";

function Productivity() {
    return (
        <div>
            {/*Header Banner */}
            <div className="banner-header section-padding valign bg-img bg-img-center bg-fixed" data-overlay-dark="6" data-background="/vendor/img/itinnove/mockup-maquettage.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center caption mt-90">
                            <h1>Doublez votre productivité</h1>
                            <h5>en vous outillant</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="savoye-project section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center animate-box" data-animate-effect="fadeInUp">
                            <div className="section-title">Les points d'améliorations</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="projects3 animate-box" data-animate-effect="fadeInUp">
                                <figure><img src="/vendor/img/itinnove/process/reunion.jpg" alt="" className="img-fluid"/></figure>
                                <div className="caption">
                                    <h6>IT INNOVE</h6>
                                    <h4>Des données éparpillées</h4>
                                    <p>Messageries, tâches, facturation, vous avez des données de partout. </p>
                                    <a href="/rendez-vous" className="btn float-btn flat-btn mr-5">Prendre un rdv</a>
                                </div>
                            </div>
                            <div className="projects3 left animate-box" data-animate-effect="fadeInUp">
                                <figure><img src="/vendor/img/itinnove/process/maquettage.jpg" alt="" className="img-fluid"/></figure>
                                <div className="caption">
                                    <h6>IT INNOVE</h6>
                                    <h4>Des tâches sans valeur ajoutée</h4>
                                    <p>Correspondance des informations, re-saisie, communication, vous effectuez chaque jour des tâches sans valeur ajoutée</p>
                                    <a href="/rendez-vous" className="btn float-btn flat-btn">Prendre un rdv</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="savoye-project section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center animate-box" data-animate-effect="fadeInUp">
                            <div className="section-title">Notre process</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="projects3 animate-box" data-animate-effect="fadeInUp">
                                <figure><img src="/vendor/img/itinnove/process/audit.png" alt="" className="img-fluid"/></figure>
                                <div className="caption">
                                    <h6>IT INNOVE</h6>
                                    <h4>Audit de votre organisation</h4>
                                    <p>Nous étudions votre processus internes, votre communication avec vos clients et fournisseurs.</p>
                                    <a href="/rendez-vous" className="btn float-btn flat-btn mr-5">Prendre un rdv</a>
                                </div>
                            </div>
                            <div className="projects3 left animate-box" data-animate-effect="fadeInUp">
                                <figure><img src="/vendor/img/itinnove/process/dev-mobile.jpg" alt="" className="img-fluid"/></figure>
                                <div className="caption">
                                    <h6>IT INNOVE</h6>
                                    <h4>Développement de la plateforme</h4>
                                    <p>Nous développons la plateforme web et/ou mobile pour centraliser vos données, les automatiser et les traiter. </p>
                                    <a href="/rendez-vous" className="btn float-btn flat-btn">Prendre un rdv</a>
                                </div>
                            </div>
                            <div className="projects3 animate-box" data-animate-effect="fadeInUp">
                                <figure><img src="/vendor/img/itinnove/process/ameliorationcontinue.png" alt="" className="img-fluid sepia"/></figure>
                                <div className="caption">
                                    <h6>IT INNOVE</h6>
                                    <h4>Support</h4>
                                    <p>Nous assurons un support pour former vos collaborateurs et vos clients et fournisseurs et nous améliorons vos process en continue. </p>
                                    <a href="/rendez-vous" className="btn float-btn flat-btn">Prendre un rdv</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Radium(Productivity);
